import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import ShareContent from "../../components/share-content/share-content.component"
import WhitepaperHero from "../../layouts/WhitepaperHero"
import RelatedWhitepapers from "../../layouts/RelatedWithepapers"

export const query = graphql`
  query whitepaperQuery($id: String!) {
    wpWhitepaper(id: { eq: $id }) {
      id
      title
      content
      uri
      slug
      excerpt
      date(formatString: "MMMM DD, YYYY")
      whitepaperCategories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      whitepaperBuilder {
        description
        whitepaperImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
        whitepaper {
          localFile {
            url
          }
        }
      }
    }
    allWpWhitepaper(limit: 3, filter: { id: { nin: [$id] } }) {
      nodes {
        excerpt
        uri
        title
        whitepaperBuilder {
          description
        }
        id
        featuredImage {
          node {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
const WhitepaperTemplate = ({ data }) => {
  const {
    seo,
    slug,
    whitepaperBuilder,
    title,
    featuredImage,
    categories,
    author,
    date,
    uri,
  } = data.wpWhitepaper

  // const layouts = postBuilder.layouts || []
  // {...postBuilder.pageConfiguration}
  return (
      <Layout seo={seo} title={title}>
        <WhitepaperHero
            title={title}
            image={whitepaperBuilder?.whitepaperImage}
            description={whitepaperBuilder?.description}
            url={whitepaperBuilder?.whitepaper?.localFile?.url}
        />
        <ShareContent
            description={"Like this Whitepaper? Share it"}
            postUrl={`/resources${uri}`}
            haveVideo={false}
        />
        <RelatedWhitepapers
            whitepapers={data?.allWpWhitepaper?.nodes}
            title="Related Whitepapers"
        />
      </Layout>
  )
}

export default WhitepaperTemplate