import React from "react"

import * as S from "./share-content.styles"

const ShareContent = ({ description, postUrl, haveVideo }) => {
  return (
    <S.SocialNetworkContent>
      <S.Description>{description}</S.Description>
      <S.SocialNetwork>
        <S.Link
            url={`https://www.facebook.com/sharer/sharer.php?u=https://www.ready-robotics.com${postUrl}`}
            target="_blank"
        >
          <S.IconFB />
        </S.Link>
        <S.Link
            url={`https://www.linkedin.com/sharing/share-offsite/?url=https://www.ready-robotics.com${postUrl}`}
            target="_blank"
        >
          <S.IconLinkedIn />
        </S.Link>
        <S.Link
            url={`https://twitter.com/intent/tweet?&url=https://www.ready-robotics.com${postUrl}`}
            target="_blank"
        >
          <S.IconTwitter />
        </S.Link>
        {/*<S.Link url={postUrl} target="_blank">*/}
        {/*  <S.IconInstagram />*/}
        {/*</S.Link>*/}
        {/*{haveVideo && (*/}
        {/*  <S.Link url={postUrl} target="_blank">*/}
        {/*    <S.IconYoutube />*/}
        {/*  </S.Link>*/}
        {/*)}*/}
      </S.SocialNetwork>
    </S.SocialNetworkContent>
  )
}

export default ShareContent
