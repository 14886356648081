import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"

export const Section = styled(SectionWrapper)``
export const Title = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin-bottom: 1.3em;
  text-transform: capitalize;
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Wrapper = styled(Container)``
