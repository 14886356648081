import React from "react"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

import * as S from "./card-whitepaper.styles"
import { textEllipsis } from "../../utils"

const CardWhitepaper = ({ image, title, description, url }) => {
  return (
    <S.CardDiv url={url}>
      <S.Image img={image} alt="wtPaper" />
      <S.ContentDiv>
        <S.Title>{textEllipsis(title, 44)}</S.Title>
        <S.Description>{textEllipsis(description, 116)}</S.Description>
        <S.LinkWrapper>
          <S.LinkText>Get Whitepaper</S.LinkText>
          <ArrowForwardIcon />
        </S.LinkWrapper>
      </S.ContentDiv>
    </S.CardDiv>
  )
}

export default CardWhitepaper
