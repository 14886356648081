import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Dialog, Modal } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding-bottom: 0;
  position: relative;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(282)};
`
export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //position: relative;
`
export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  color: ${({ theme }) => theme.palette.tertiary.main};
  max-width: 518px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const ButtonDiv = styled.div`
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(44)};
`
export const Button = styled(CustomButton)``
export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  max-width: 650px;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(345)};
`

export const ImageDiv = styled.div`
  //.gatsby-image-wrapper {
  //  max-height: 500px;
  //  min-height: 500px;
  //  img {
  //    object-fit: contain !important;
  //  }
  //}
`
export const Image = styled(CustomBgImage)`
  max-width: 550px;
  width: 100%;
  height: 500px;
  //height: 509px;
  //width: 360px;
  //width: 100%;
  position: absolute;
  bottom: -25%;
  //overflow: hidden;
  //border-radius: 10px;
  //box-shadow: 0 31px 49px 0 rgba(0, 0, 0, 0.23);
  //.gatsby-image-wrapper {
  //  max-height: 500px;
  //  min-height: 500px;
  //
  //}
  img {
    object-fit: contain !important;
    ${({ theme }) => theme.breakpoints.down("md")} {
      padding: 0 2em;
    }
  }
`
export const Gradient = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
`
