import styled, { css } from "styled-components"
import {
  Dialog,
  FormControlLabel,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material"
import TextField from "@mui/material/TextField"
import CustomLink from "../custom-link/custom-link.component"
import CancelIcon from "@mui/icons-material/Cancel"

const textInputStyle = css`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(20)};
`

export const CustomForm = styled.form``

export const CustomField = styled(TextField)`
  width: 100%;

  .MuiInputBase-root {
    color: #566171;
    border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.main};
  }
  .MuiInputLabel-root {
    ${textInputStyle}
  }
`

export const CustomInput = styled(InputLabel)`
  ${textInputStyle}
`

export const Link = styled(CustomLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`

export const InfoWrapper = styled.div`
  display: flex;
`

export const InfoText = styled.div`
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  max-width: 80%;
`

export const CustomCheckbox = styled(FormControlLabel)`
  flex-wrap: wrap;
  margin-top: 1em;
  align-items: flex-start;

  .MuiTypography-root {
    max-width: calc(100% - 42px);
  }

  .MuiButtonBase-root {
    padding-top: 2px;
  }
`
