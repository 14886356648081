import React from "react"
import { Grid } from "@mui/material"
import CardWhitepaper from "../../components/card-whitepaper/card-whitepaper.component"
import parse from "html-react-parser"
import * as S from "./related-whitepapers.styles"

const RelatedWhitepapers = ({ title, whitepapers, url }) => {
  return (
    <S.Section>
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <Grid container spacing={3} justifyContent="center">
          {whitepapers.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <CardWhitepaper
                image={item.featuredImage?.node}
                description={parse(item?.excerpt)}
                title={item.title}
                url={`/resources${item.uri}`}
              />
            </Grid>
          ))}
        </Grid>
      </S.Wrapper>
    </S.Section>
  )
}

export default RelatedWhitepapers
