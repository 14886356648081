import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"
import CustomLink from "../custom-link/custom-link.component"

export const CardDiv = styled(CustomLink)`
  &:hover {
    svg {
      transform: translateX(25%);
    }
  }
`
export const Image = styled(CustomImage)`
  width: 100%;
  height: 310px;
  overflow: hidden;
  border-radius: 5px;
`
export const ContentDiv = styled.div``
export const Title = styled.h3`
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 500;
  margin-top: ${({ theme }) => theme.typography.pxToRem(27)};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(24)};
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Description = styled.p`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text.content};
`

export const LinkText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-weight: 500;
  letter-spacing: 0;
  text-transform: capitalize;
  line-height: 24px;
  margin-right: 0.3em;
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0;
  svg {
    transition: all 0.3s ease;
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`
