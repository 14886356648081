import React, { useState } from "react"

import * as S from "./whitepaper-hero.styles"
import WhitepaperForm from "../../components/whitepaper-form/whitepaper-form.component"
import CustomDialog from "../../components/custom-dialog/custom-dialog.component"
import { useLocation } from "@reach/router"
import { gtagConversionWhitePaperClick } from "../../gtag-utils"

const WhitepaperHero = ({ title, url, description, image }) => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const handleOpen = () => {
    gtagConversionWhitePaperClick(location.href)
    setOpen(true)
  }
  const handleClose = () => setOpen(false)
  return (
    <S.Section>
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <S.ButtonDiv>
          <S.Button onClick={handleOpen} className="blue">
            Download Free Whitepaper
          </S.Button>
          {/*<S.Button href={url} download target="_blank" className="blue">*/}
          {/*  Download Free Whitepaper*/}
          {/*</S.Button>*/}
        </S.ButtonDiv>
        <S.Description>{description}</S.Description>
        {/*<S.ImageDiv>*/}
        <S.Image img={image} alt="whitepaper" />
        {/*<S.Gradient />*/}
        {/*</S.Image>*/}
        {/*</S.ImageDiv>*/}
        <CustomDialog
          open={open}
          handleClose={handleClose}
          title="Get the Whitepaper"
        >
          <WhitepaperForm url={url} handleClose={handleClose} />
        </CustomDialog>
      </S.Wrapper>
    </S.Section>
  )
}

export default WhitepaperHero
