import React, { useRef, useState } from "react"
import * as S from "./whitepaper-form.styles"
import { Checkbox, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"
import Typography from "@mui/material/Typography"
import CustomButton from "../custom-button/custom-button.component"
import { useLocation } from "@reach/router"
import { getCookie, isBrowser, submitGravityForm } from "../../utils"
import Box from "@mui/material/Box"
import Spinner from "../spinner/spinner.component"
import { gtagConversionWhitePaperDownload } from "../../gtag-utils"

const WhitepaperForm = ({ url, handleClose }) => {
  const location = useLocation()
  const recaptchaRef = useRef()
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleCaptchaChange = () => {
    setError(false)
  }

  const { register, handleSubmit } = useForm()
  const onSubmit = data => {
    setIsLoading(true)
    const captchaVerification = recaptchaRef.current.getValue()
    if (captchaVerification === "") {
      setError(true)
      setIsLoading(false)
      return
    }
    submitGravityForm(
      {
        input_1: data.first_name,
        input_2: data.last_name,
        input_3: data.email,
        input_4: data.company,
        // input_5: data.updates,
        input_6: location.href,
      },
      1
    ).then(response => {
      setIsLoading(false)
      handleClose()
      if (isBrowser()) {
        if (
          getCookie("cky-consent") === "yes" &&
          getCookie("cookieyes-advertisement") === "yes"
        ) {
          const linkedinPixelWrapper = document.querySelector(
            ".linkedin-pixel-wrapper"
          )
          linkedinPixelWrapper.innerHTML =
            '<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1793905&conversionId=5297225&fmt=gif" />'
        }
        gtagConversionWhitePaperDownload(location.href)
        window.open(url, "_blank")
      }
    })
  }

  return (
    <S.CustomForm onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <Spinner />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <S.CustomField
            required
            label="First Name"
            variant="standard"
            {...register("first_name")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <S.CustomField
            required
            label="Last Name"
            variant="standard"
            {...register("last_name")}
          />
        </Grid>

        <Grid item xs={12}>
          <S.CustomField
            required
            id="email"
            label="Email Address"
            variant="standard"
            {...register("email")}
          />
        </Grid>
        <Grid item xs={12}>
          <S.CustomField
            required
            id="company"
            label="Company"
            variant="standard"
            {...register("company")}
          />
        </Grid>
      </Grid>
      {/*<S.InfoWrapper>*/}
      {/*<Checkbox />*/}
      <S.CustomCheckbox
        control={<Checkbox defaultChecked {...register("updates")} />}
        label="Receive updates from READY Robotics on the future of manufacturing,
            robotics, and automation. You can unsubscribe from communications at
            any time. View our Privacy Policy."
      />
      <Box sx={{ margin: "1em 0" }}>
        <ReCAPTCHA
          sitekey={process.env.GATSBY_CAPTCHA_CLIENT_KEY}
          size="normal"
          render="explicit"
          onChange={handleCaptchaChange}
          className="g-recaptcha"
          ref={recaptchaRef}
        />
        {error && (
          <Typography style={{ color: "#F54343" }}>
            Please enter captcha
          </Typography>
        )}
      </Box>
      <S.Link url="/privacy-policy/" target="_blank">
        Terms and Privacy Policy.
      </S.Link>
      <S.ButtonDiv>
        <CustomButton
          className="blue"
          children="Download Whitepaper"
          type="submit"
        />
      </S.ButtonDiv>
      <div className="linkedin-pixel-wrapper" />
    </S.CustomForm>
  )
}

export default WhitepaperForm
