import styled, { css } from "styled-components"
import CustomLink from "../custom-link/custom-link.component"
import FacebookIcon from "@mui/icons-material/Facebook"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import TwitterIcon from "@mui/icons-material/Twitter"
import InstagramIcon from "@mui/icons-material/Instagram"
import YouTubeIcon from "@mui/icons-material/YouTube"

const IconDesign = css`
  color: ${({ theme }) => theme.palette.primary.main};
  width: 40px;
  height: 40px;
`

export const SocialNetworkContent = styled.div`
  max-width: 628px;
  width: 100%;
  margin: auto;
  border-top: 2px solid #979797;
  padding-top: 2em;
  padding-bottom: 2em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 0.5em;
  }
`
export const Description = styled.p`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  text-align: center;
`
export const SocialNetwork = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  //justify-content: space-around;
  //max-width: 334px;
  margin: auto;
`
export const Link = styled(CustomLink)``
export const IconFB = styled(FacebookIcon)`
  ${IconDesign}
`
export const IconLinkedIn = styled(LinkedInIcon)`
  ${IconDesign}
`
export const IconTwitter = styled(TwitterIcon)`
  ${IconDesign}
`
export const IconInstagram = styled(InstagramIcon)`
  ${IconDesign}
`
export const IconYoutube = styled(YouTubeIcon)`
  ${IconDesign}
`
